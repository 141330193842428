import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import './styles/base.scss';
import App from './App';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/oracle",
    element: <div className='lalal'>lalaalalalalalalalalalalla</div>,
  },
  {
    path: "/neoracle",
    element: <div className='lalal'>123456lalaalalalalalalalalalalla</div>,
  },
]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
